<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="crmCustomers"
                          base-table="customer"
                          columnsPrefix="common.column.crm_customers."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.crm_customers"
                >
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal', {customer_id: row.item.id}, refreshTable)">
                                {{ $t('common.button.add_todo') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="getModules().includes('crm-customer-reports') && $root.hasAccess('crm-customer-reports')"
                                @click="$root.$children[0].openModal('customer-reports', {customerId: row.item.id})">
                                {{ $t('common.button.reports') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CrmCustomers',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Crm', ['getCustomersTable']),
        ...mapGetters('CommonData', ['getModules']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Crm/fetchCustomers', 'getCustomersTable');
        },
        ctxBaseParams() {
            return {
                'startDate': this.dateStart,
                'endDate': this.dateEnd,
            }
        },
    },
}
</script>